import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				SSS | Camo Clash Paintball Arena'da Sıkça Sorulan Sorular
			</title>
			<meta name={"description"} content={"Hazır, Nişan Al, Netleştir - Camo Clash ile İlgili Sıkça Sorulan Sorular Yanıtlandı"} />
			<meta property={"og:title"} content={"SSS | Camo Clash Paintball Arena'da Sıkça Sorulan Sorular"} />
			<meta property={"og:description"} content={"Hazır, Nişan Al, Netleştir - Camo Clash ile İlgili Sıkça Sorulan Sorular Yanıtlandı"} />
			<meta property={"og:image"} content={"https://jake-rembis.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://jake-rembis.com/img/4905919.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://jake-rembis.com/img/4905919.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://jake-rembis.com/img/4905919.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://jake-rembis.com/img/4905919.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://jake-rembis.com/img/4905919.png"} />
			<meta name={"msapplication-TileImage"} content={"https://jake-rembis.com/img/4905919.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" quarkly-title="FAQ-4">
			<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--dark">
				SSS - Sıkça Sorulan Sorular
			</Text>
			<Text margin="0px 0px 70px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#111" sm-margin="0px 0px 50px 0px">
				Camo Clash Paintball Arena'da savaş alanına girmek beraberinde pek çok soruyu da getiriyor. Sizi bilgilendirmek ve aksiyona hazır tutmak için, işte acemilerimizden gelen bazı yaygın soruların yanıtları.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="50px 50px"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--dark">
						S1: Paintball yeni başlayanlar için güvenli midir?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#111">
						A1: Kesinlikle. Her oyundan önce kurallar ve ekipman hakkında tam brifinglerle güvenlik bizim önceliğimizdir. Ayrıca, görevlilerimiz adil oyun ve güvenliği sağlamak için her zaman hazırdır.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--dark">
						S2: Paintball oynamak için ne giymeliyim?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#111">
						A2: Cildinizi korumak için uzun kollu ve pantolon giymenizi öneririz. Koruyucu ekipmanımız geri kalanını kapsayacaktır.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--dark">
						S3: Kendi paintball ekipmanımı getirebilir miyim?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#111">
						A3: Kendi ekipmanınızı getirebilirsiniz, ancak personelimiz tarafından güvenlik kontrolüne tabi tutulacaktır.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--dark">
						S4: Oyuncular için yaş sınırlaması var mı?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#111">
						A4: Oyuncular 12 yaşında veya daha büyük olmalıdır, 18 yaşın altındakiler için izin gereklidir.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--dark">
						S5: Camo Clash'te bir seans veya etkinlik için nasıl rezervasyon yapabilirim?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#111">
						A5: Rezervasyonlar telefon veya e-posta yoluyla yapılabilir. Ekibimiz mükemmel paintball macerasını planlamanıza yardımcı olacaktır.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--dark">
						S6: Özel partilere ve kurumsal etkinliklere ev sahipliği yapıyor musunuz?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#111">
						A6: Evet, oyun senaryoları ve yemek seçenekleriyle birlikte her tür etkinlik için özel paketlerimiz var.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Contacts />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});